import React from "react"
import styled from "@emotion/styled"
import Grid from "./Grid"

const Label = styled.label`
    font-family: 'GTWalsheim';
    display:block;
    margin-bottom:1vh;
`

const Button = styled.button`
    background-color:#F4F7FA;
    border:2px solid #334259;
    color:#334259;
    padding:0.75rem;
    margin-top:2vh;
    margin-bottom:1vh;
    font-family:'GTWalsheim';
    font-size:1rem;
    cursor:pointer;
`

const Input = styled.input`
    display:block;
    width:100%;
    border-width:0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border:2px solid #334259;
    color:#334259;
    padding:1rem;
    background-color:#F4F7FA;
    font-family:'GTWalsheim';
    font-size:1rem;
    &:focus{
        outline: none;
    }
`

const Textarea = styled.textarea`
    display:block;
    width:100%;
    border-width:0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border:2px solid #334259;
    color:#334259;
    padding:1rem;
    background-color:#F4F7FA;
    font-family:'GTWalsheim';
    font-size:1rem;
    resize: vertical;
    &:focus{
        outline: none;
    }
`

const ContactForm = props => {
    return (
        <form name="Contact Form" action="/contactSuccess" method="post" data-netlify="true" data-netlify-honeypot="bot-field" enctype="multipart/form-data">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="Contact Form" />
            <Grid lines={2} gap={4}>
                <fieldset>
                    <Label>Votre nom</Label> 
                    <Input type="text" name="name" required/>   
                </fieldset>
                <fieldset>
                    <Label>Votre email</Label>
                    <Input type="email" name="email" required/>
                </fieldset>
                <fieldset>
                    <Label>Votre message</Label>
                    <Textarea rows="5" name="message" required/>
                </fieldset>
                <fieldset>
                    <Label>Votre fichier (Votre cachier des charges par exemple)</Label>
                    <Input type="file" name="file"/>
                </fieldset>
            </Grid>
            <Button type="submit">Envoyer</Button>
        </form>
    )
}
export default ContactForm