import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Container from "../components/Container"
import ContactForm from "../components/ContactForm"
import { css } from "@emotion/core"

const ContactPage = () => (
    <Layout>
        <SEO title="Contactez moi" />
        <Container>
            <div
                css={css`
                    display:flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom:8vh;
                    @media (max-width: 960px) {
                        text-align:center;
                        display:block;
                    }
                `}
            >
                <div>
                    <h1
                        css={css`
                            font-family: 'GTWalsheim';
                            font-size:4rem;
                            letter-spacing: -0.08em;
                            line-height:10rem;
                            color:#1D3557;
                            margin-top:4vh;

                            @media (max-width: 960px) {
                                line-height:4rem;
                            }
                        `}
                    >
                        Contactez-moi
                    </h1>
                    <h2
                        css={css`
                            font-family: 'FreightTextBook';
                            font-size:2rem;
                            line-height:2.5rem;
                            opacity:0.8;
                            font-weight: 600;
                            max-width:45rem;
                        `}
                    >
                        Besoin d'un conseil ou d'un devis?
                    </h2>
                    <p
                        css={css`
                            margin-top: 2.5vh;
                            font-family: 'FreightTextBook';
                            font-size:1.25rem;
                            opacity:0.6;
                            max-width:40rem;
                            margin-bottom:6vh;
                            font-weight:800;
                        `}
                    >
                        Je vous invite à me présenter votre projet en quelques lignes. Vous pouvez aussi directement me transférer votre cahier des charges depuis le formulaire dans le cas d'une demande de devis. Je m'engage à vous répondre dans les plus brefs délais.
                    </p>
                </div>
                <div css={css`
                    background-image:url(${require('../images/illustration_enveloppe.svg')});
                    background-size:100%;
                    background-repeat:no-repeat;
                    width:550px;
                    height:420px;
                    margin-top:2vh;
                    @media (max-width: 960px) {
                        display:none;
                    }
                `}>
                </div>
            </div>
            <ContactForm />
        </Container>
    </Layout>
)

export default ContactPage
